'use client'
import useSWR from 'swr'
import {useEffect, useState} from "react";
import useGebruiker from "./useGebruiker";

/**
 /**
 *
 * @returns {(function(*, null=, null=, boolean=): (*))|*}
 */
const fetcherTekstFragmenten = (url, taalNaam) => fetch(`${process.env.NEXT_PUBLIC_BACKEND}/api/vertaling/tekstfragmenten?taal=${taalNaam}`).then((res) => res.json());

export const getClientSideCookie = (name) => {
    if (typeof document === 'undefined') return '';
    return document.cookie
        .split('; ')
        .find((row) => row.startsWith(`${name}=`))
        ?.split('=')[1];
};

export const useGetTekstFragmenten = (taalNaam) => {
    let locale
    if (taalNaam === undefined) {
        locale = 'nl'
    } else {
        locale = taalNaam
    }
    const {
        data,
        error,
        isLoading,
    } = useSWR([`${process.env.NEXT_PUBLIC_BACKEND}/api/vertaling/tekstfragmenten?taal=${locale}`, locale],
        ([url, locale]) => locale ? fetcherTekstFragmenten(url, locale) : null,
        {
            refreshInterval: 0, // do not refesh with an interval
            revalidateIfStale: true,
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
        }) // eens per uur

    return {
        data, error, isLoading
    }
}

export const useTekstClient = () => {
    const {ingelogdeGebruiker} = useGebruiker()
    const [taalNaam, setTaalNaam] = useState()
    const {data, isLoading} = useGetTekstFragmenten(taalNaam)
    const [isLoading2, setIsLoading2] = useState(false)

    useEffect(() => {
        let getNaam = getClientSideCookie('locale')
        setTaalNaam(getNaam)
    }, [])


    function getBreak(text) {
        let tempTxt = text.split('<br/>')
        if (tempTxt.length > 1) {
            return tempTxt.map((item, i) => <span key={item + i}>{item}<br/></span>);
        }
        return [tempTxt];

    }

    const tekst = (pad, vervangen = null, aantal = null, returnString = false) => {
        // if(pad === 'pagina:activiteiten:progressSteps:5-herinnering-klant:tooltip_info:gebruiker:consultancy'){
        //     let a = true;
        // }

        if (!data || isLoading) return ' ';
        else if (!isLoading2) setIsLoading2(true)

        const instelling = ingelogdeGebruiker?.instellingen?.filter((x) => x.instelling === "Paden Tonen")
        let textObj = data.find(vertaling => vertaling.pad === pad)
        let text = textObj?.tekst;


        if (!text) {
            return ' ';// pad
        }
        if (vervangen) {
            for (const [key, value] of Object.entries(vervangen)) {
                text = text.replace(`:${key}`, value);
            }
        }

        if (instelling && instelling[0]?.gebruikers_instelling?.ingestelde_optie === 1) {
            return pad
        } else if (aantal) {
            let tekstReturn = "";
            const tekstSplit = text.split("|")

            aantal <= 1 ? tekstReturn = tekstSplit[0].trim() : tekstReturn = tekstSplit[1].trim()

            return tekstReturn
        } else if (returnString) {
            return text
        } else {

            let arr1 = [];
            const regexBold = /<b>(.*)<\/b>/g;
            let match;
            while ((match = regexBold.exec(text)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (match.index === regexBold.lastIndex) {
                    regexBold.lastIndex++;
                }

                let textBefore = text.substring(0, match.index);
                let textAfter = text.substring(match.index + match[0].length, text.length - 1);

                if (textBefore.length > 0)
                    arr1.push(textBefore);

                let br = getBreak(match[1]);
                arr1.push(<b key={`${Math.random()} ${br}`}>{br}</b>);
                text = textAfter; // Alle tekst controleren na deze match
            }

            const regexItalic = /<i>(.*)<\/i>/g;
            while ((match = regexItalic.exec(text)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (match.index === regexItalic.lastIndex) {
                    regexItalic.lastIndex++;
                }

                let textBefore = text.substring(0, match.index);
                let textAfter = text.substring(match.index + match[0].length, text.length - 1);

                if (textBefore.length > 0)
                    arr1.push(textBefore);

                let br = getBreak(match[1]);
                arr1.push(<i key={`${Math.random()} ${br}`}>{br}</i>);
                text = textAfter; // Alle tekst controleren na deze match
            }
            if (text !== '>') // de regex van <i> is wel goed, maar toch geeft hij >
                arr1.push(text);

            let arr2 = [];
            for (let i = 0; i < arr1.length; i++) {
                if (typeof arr1[i] === "object") {
                    arr2.push(arr1[i]);
                } else {
                    let txt = getBreak(arr1[i]);
                    // Wanneer het gewoon tekst is en geen objecten bevat dan alleen de tekst terug geven.
                    // Dit speciaal voor labels en titels die niet met objecten kunnen omgaan.
                    if (arr1.length === 1 && txt.length === 1 && typeof txt[0][0] === "string") {
                        return txt[0];
                    } else {
                        arr2 = [...arr2, ...getBreak(arr1[i])];
                    }
                }

            }
            return arr2;
        }

    }

    return {tekst, isLoadingTekst: isLoading2};
}