"use client"
import React from 'react';
import Select, {components} from 'react-select';
import {useTekstClient} from "hooks/useTekstClient";


const SelectBedrijven = (props) => {
    const {tekst, isLoadingTekst} = useTekstClient()
    // const SelectBedrijvenOption = props => {
    //     return (
    //         <components.Option {...props} key={"option" +props.data.id}>
    //             <div key={"optionDiv" +props.data.id}
    //                 style={props.data.actief === false ? {textDecorationLine: 'line-through'} : {}}>{props.data.label}</div>
    //             <div style={{fontSize: 12, color: '#aeb4bb'}}>{props.data.subLabel}</div>
    //         </components.Option>
    //     );
    // };

    const SelectBedrijvenOption = props => {
        return (
            <components.Option {...props} key={"option" + props.data.id}>
                <div key={"optionDiv" + props.data.id}
                     style={props.data.actief === false ? {textDecorationLine: 'line-through'} : {}}>{props.data.naam}</div>
                <div style={{
                    fontSize: 12,
                    color: '#aeb4bb'
                }}>{'debi: ' + props.data.debinr + ' rela: ' + props.data.relanr}</div>
            </components.Option>
        );
    };

    const selectedLabel = props => {
        return <div key={"optionDiv" + props.id}>
            <div>{props.naam}</div>
            <div style={{fontSize: 10, color: '#aeb4bb'}}>{'debi: ' + props.debinr + ' rela: ' + props.relanr}</div>
        </div>


    }

    const SelectBedrijvenFilterOptions = (candidate, input) => {

        if (input) {
            return (typeof candidate.data.debinr !== 'undefined' && candidate.data.debinr.toString().startsWith(input))
                || (typeof candidate.data.relanr !== 'undefined' && candidate.data.relanr.toString().startsWith(input))
                || candidate.data.naam.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().includes(input.toLowerCase());
        }
        return true;
    };


    return (<div>
        <Select
            name={'bedrijven[]'}
            components={{Option: SelectBedrijvenOption}}
            getOptionLabel={option => selectedLabel(option)}
            filterOption={SelectBedrijvenFilterOptions}
            placeholder={tekst('algemeen:Selecteer een ')}
            value={props.defaultValue || props.selected}
            onChange={props.handleChange}
            {...props}
        />
    </div>)
};
export default React.memo(SelectBedrijven);
