export const useLoading = () => {

    const setIsLoading = (boolean) => {

        const element = document.querySelector('#globLoadingIndicator')

        element.style.display = boolean ? 'block' : 'none'

    }

    return {setIsLoading}


}