'use client'

import React from 'react'
import { FaChevronRight } from "react-icons/fa";

import { usePathname, useSearchParams } from 'next/navigation'

import Link from 'next/link'
import style from './breadcrumb.module.scss'

const NextBreadcrumb = () => {

    const paths = usePathname()
    let path = paths
    if (paths?.startsWith('/mijn-mkg/support/kenniscentrum/id/')) {
        path = paths.split("/mijn-mkg/support/kenniscentrum/id/").pop()
        path = '/mijn-mkg/support/kenniscentrum/' + path.split('/').pop()
    }

    if (paths?.startsWith('/mijn-mkg/support/storingen/')) {
        path = paths.split("/mijn-mkg/support/storingen/").pop()
        path = '/mijn-mkg/support/storingen/' + path.split('/').pop()
    }

    if (paths?.startsWith('/mijn-mkg/academy/')) {
        path = paths.split("/mijn-mkg/academy/").pop()
        path = '/mijn-mkg/academy/' + path.split('/').pop()
    }
    const searchParams = useSearchParams()
    const pathNames = path.split('/').filter( path => path )
    const capitalizeLinks = true

    const params = new URLSearchParams(searchParams);

    return (
        <div>
            <ul className={style.containerClasses}>
                {
                    pathNames.map( (link, index, array) => {
                        let href = `/${pathNames.slice(0, index + 1).join('/')}`
                        let itemLink = capitalizeLinks ? link[0].toUpperCase() + link.slice(1, link.length) : link

                        //Append searchParams to last breadcrumb url
                        if(index === array.length - 1){
                            href = href + `?${params.toString()}`
                        }
                        return (
                            <React.Fragment key={index}>
                                <li className={style.crumb} >
                                    <Link href={href}>{itemLink}</Link>
                                </li>
                                {pathNames.length !== index + 1 && <FaChevronRight style={{fontSize: '12px'}}/>}
                            </React.Fragment>
                        )
                    })
                }
            </ul>
        </div>
    )
}
export default NextBreadcrumb
