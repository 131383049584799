import React, {useCallback, useState} from 'react'
import {useSearchParams, useRouter, usePathname} from "next/navigation";

export const useSetSearchParams = () => {
    const router = useRouter()
    const pathname = usePathname()
    const searchParams = useSearchParams()
    const hasParams = searchParams.size > 0

    //
    // const setParams = useCallback(
    //     (name, value, anchor=null) => {
    //         const params = new URLSearchParams(searchParams)
    //         params.set(name, value)
    //
    //         const url = `${pathname}?${params.toString()}${anchor ? `#${anchor}`: ''}`
    //
    //         router.push(hasParams ? url : pathname)
    //     },
    //     [searchParams]
    // )
    /**
     * Navigate to the same route with the given params
     * @param {string|URLSearchParams} newQueryString
     */
    const doPush = (newQueryString) => {
        router.push(pathname + '?' + newQueryString.toString());
    }

    /**
     * navigate to the same route without params
     */
    const resetParams = () => {
        router.push(pathname)
    }

    /** Alter the multiple params in the url
     * there are 2 ways to use this function:
     * @example setParams([{name: 'foo', value: 1}, {name: 'bar', value: 2}]) > this will change the url immediately
     * @example setParams([{name: 'foo', value: 1}, {name: 'bar', value: 2}], searchParams) > this will return the updated version of the searchParams so you can update multiple params in the url and at the end execute the function doPush
     */
    const setParams = useCallback((array, url = null) => {
        let params = url ? new URLSearchParams(url) : new URLSearchParams(searchParams);

        array.forEach(param => {
            const {name, value} = param
            params.set(name, value)
        })

        if (!url) doPush(params)
        else return params.toString()
    }, [searchParams]);

    /** Delete the multiple params in the url
     * there are 2 ways to use this function:
     * @example deleteParams([{name: 'foo'}, {name: 'bar', value: 2}]) > this will change the url immediately
     * @example deleteParams([{name: 'foo', value: 1}, {name: 'bar', value: 2}], searchParams) > this will return the updated version of the searchParams so you can update multiple params in the url and at the end execute the function doPush
     */
    const deleteParams = useCallback(
        (array, url = null) => {
            let params = url ? new URLSearchParams(url) : new URLSearchParams(searchParams);

            array.forEach(param => {
                const {name, value} = param
                value ? params.delete(name, value) : params.delete(name)
            })


            if (!url) doPush(hasParams ? params : '')
            else return params.toString()
        },
        [searchParams]
    )


    /**
     * Get a new searchParams string by merging the current
     * SearchParams with a provided key/value pair
     * @param {string} name
     * @param {string|int} value
     * @param {string|null} url
     * @returns {string}
     */
    const createQueryString = useCallback((name, value, url = null) => {
        let params = url ? new URLSearchParams(url) : new URLSearchParams(searchParams);
        if (value === null) params.delete(name)
        else params.set(name, value)

        return params.toString()
    }, [searchParams])

    /**
     * Update one param in the url
     * if the param was previous, set it will be overwritten
     * @param {string} name
     * @param {string|int|null} value if null then the value will be deleted
     * @param {string|null} url
     * @returns {string}
     */
    const updateParam = (name, value, url = null) => {
        let paramQuery = createQueryString(name, value, url)
        if (!url) doPush(paramQuery)
        else return paramQuery
    }

    /**
     * Delete one param in the url
     * @param {string} name
     * @param {string|null} url
     * @returns {string}
     */
    const deleteParam = (name, url = null) => {
        return updateParam(name, null, url)
    }


    /**
     * Add a int to and array param in the url
     * @param {string} name
     * @param {string|int} value
     * @param {string|null} url
     * @returns {string}
     * @example addParamArrayInt('typen', 4); // if it was typen=1,2 then it will be after this function typen=1,2,4
     */
    const addParamArrayInt = (name, value, url = null) => {
        let params = url ? new URLSearchParams(url) : new URLSearchParams(searchParams);
        let currentValue = params.has(name) ? params.get(name).split(',').map(x => parseInt(x)) : []
        let newValue = _.uniq([...currentValue, value]);
        return updateParam(name, newValue.join(','), url)
    }


    const deleteParamArrayInt = (name, value, url = null) => {
        let params = url ? new URLSearchParams(url) : new URLSearchParams(searchParams);
        let currentValue = params.has(name) ? params.get(name).split(',').map(x => parseInt(x)) : []

        if (currentValue.length === 1) {
            return deleteParam(name, url)
        } else {
            return updateParam(name, [...currentValue].filter(x => x !== value).join(','), url)
        }

    }
    const addParamArrayString = (name, value, url = null) => {
        let params = url ? new URLSearchParams(url) : new URLSearchParams(searchParams);
        let currentValue = params.has(name) ? params.get(name).split(',').map(x => x) : [];
        return updateParam(name, [...currentValue, value].join(','), url)
    }
    const deleteParamArrayString = (name, value, url = null) => {
        let params = url ? new URLSearchParams(url) : new URLSearchParams(searchParams);
        let currentValue = params.has(name) ? params.get(name).split(',').map(x => x) : []

        if (currentValue.length === 1) {
            return deleteParam(name, url)
        } else {
            return updateParam(name, [...currentValue].filter(x => x !== value).join(','), url)
        }

    }

    /** Multiple changes to the url
     * @example doMultipleChanges([{ fun: 'setParams', params: [{name: 'foo', value: 1}, {name: 'bar', value: 2}] }, { fun: 'deleteParams', params: [{ name: 'foo', value: 1}, {name: 'bar', value: 2}] } ] )
     * Alter the multiple params in the url
     * there are 2 ways to use this function:
     * @example setParams([{name: 'foo', value: 1}, {name: 'bar', value: 2}]) > this will change the url immediately
     * @example setParams([{name: 'foo', value: 1}, {name: 'bar', value: 2}], searchParams) > this will return the updated version of the searchParams so you can update multiple params in the url and at the end execute the function doPush
     * Delete the multiple params in the url
     * there are 2 ways to use this function:
     * @example deleteParams([{name: 'foo'}]) > this will change the url immediately
     * @example deleteParams([{name: 'foo'}, {name: 'bar'}], searchParams) > this will return the updated version of the searchParams so you can update multiple params in the url and at the end execute the function doPush
     */
    const doMultipleChanges = (arr) => {
        let tmpSearchParams = new URLSearchParams(searchParams)
        arr.map(({fun, params}) => {
            switch (fun) {
                case 'setParams':
                    tmpSearchParams = setParams(params, tmpSearchParams);
                    break;
                case 'deleteParam':
                    tmpSearchParams = deleteParam(params, tmpSearchParams);
                    break;
                case 'addParamArrayString':
                    tmpSearchParams = addParamArrayString(params.name, params.value, tmpSearchParams);
                    break;
                case 'deleteParamArrayString':
                    tmpSearchParams = deleteParamArrayString(params.name, params.value, tmpSearchParams);
                    break;
                case 'addParamArrayInt':
                    tmpSearchParams = addParamArrayInt(params.name, params.value, tmpSearchParams);
                    break;
                case 'deleteParamArrayInt':
                    tmpSearchParams = deleteParamArrayInt(params.name, params.value, tmpSearchParams);
                    break;
            }
        })
        doPush(tmpSearchParams)
    }

    return {
        setParams,
        deleteParams,
        updateParam,
        resetParams,
        deleteParam,
        addParamArrayInt,
        deleteParamArrayInt,
        addParamArrayString,
        deleteParamArrayString,
        doMultipleChanges
    }

}